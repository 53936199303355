import { useToast } from '@chakra-ui/react';
import { isMadPawsStore } from 'common/util';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Button from '../../atoms/Button/Button';
import Dialog from '../../atoms/Dialog/Dialog';
import { getStorage } from '../../../helpers/general';
import { LoginContainer } from './LoginForm.styled';

const LoginForm = ({
  isLoggedIn,
  handleLogin,
  customWarning = '',
  emailLogin = '',
  isLoginPage = true
}) => {
  const toast = useToast();
  const [email, setEmail] = useState(emailLogin);
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const passwordRef = useRef(null);
  const afterAuthCalls = getStorage('_afterAuth');

  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, []);

  let afterAuthCallsObject = {};

  if (afterAuthCalls) {
    afterAuthCallsObject = JSON.parse(afterAuthCalls);
  }

  const attemptLogin = e => {
    e.preventDefault();
    setLoading(true);
    handleLogin(email, password, remember)
      .then(() => {
        // console.log(response);
      })
      .catch(error => {
        toast({
          title: 'Login failed',
          description:
            error?.response?.message?.message ??
            'Please check your email and password and try again.',
          status: 'error',
          position: 'top-right',
          colorScheme: 'secondary'
        });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!isLoggedIn) {
    return (
      <LoginContainer
        $customWarning={customWarning}
        $isLoginPage={isLoginPage}
        $isMadStore={isMadPawsStore()}
      >
        <div className="loginForm center">
          <h1 className="h6-login">Login</h1>
          {'action' in afterAuthCallsObject && afterAuthCallsObject.action === 'saveWishlist' && (
            <div className="notice">Login or Create Account for wishlists</div>
          )}
          {customWarning && <span className="warning">{customWarning}</span>}
          <form onSubmit={e => attemptLogin(e)}>
            <div
              className={`inputs ${
                'action' in afterAuthCallsObject && afterAuthCallsObject.action === 'saveWishlist'
                  ? 'wishlist'
                  : ''
              }`}
            >
              <div
                className="formField"
                style={{
                  marginBottom: isMadPawsStore() ? '16px' : ''
                }}
              >
                <input
                  type="email"
                  className="input"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div
                className="formField"
                style={{
                  marginBottom: isMadPawsStore() ? '16px' : ''
                }}
              >
                <input
                  type="password"
                  className="input"
                  name="password"
                  placeholder="Password"
                  ref={passwordRef}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className={`formActions formField`}>
                <div className="formActionsOp">
                  <div className="rememberMe">
                    <input
                      type="checkbox"
                      className="formCheckbox"
                      id="remember_me"
                      name="remember_me"
                      checked={remember}
                      onChange={e => setRemember(e.target.checked)}
                      value="Remember me"
                    />
                    <label className="formLabel" htmlFor="remember_me">
                      Remember me
                    </label>
                  </div>
                  <Link className="forgot" to="/forgotpassword/">
                    Forgot password
                  </Link>
                </div>
              </div>
            </div>
            <Button disabled={loading} loading={loading} level="tertiary" lo type="buttonSubmit">
              {isMadPawsStore() ? 'Log in' : 'Login'}
            </Button>
            <Dialog
              open={dialogMessage ? true : false}
              title="Login unsuccessful"
              size="sm"
              hideBtnCancel
              disableBackdropClick
              onOk={() => setDialogMessage(false)}
              onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') {
                  return false;
                }
                setDialogMessage(false);
              }}
            >
              {dialogMessage}
            </Dialog>
          </form>
        </div>
      </LoginContainer>
    );
  } else {
    return null;
  }
};

export default LoginForm;
