import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthProvider';
import { getQuerystringValue, setStorage } from '../helpers/general';
import Button from '../components/atoms/Button/Button';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import { Center, Image } from '@chakra-ui/react';
import { navigate } from 'gatsby';
import * as pcStyles from './login.module.css';
import * as mpsStyles from './login.mps.module.css';
import { storeStyle, isMadPawsStore } from '../common/util';
import LoginForm from '../components/molecules/LoginForm/LoginForm';

const styles = storeStyle({ mpsStyles, pcStyles });
const LoginPage = () => {
  const auth = useContext(AuthContext);
  const handleLogin = auth && auth.login;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const [pageLoaded, setLoaded] = useState(false);
  let redirectTo = '/account';

  const isMobile = () => {
    return window.innerWidth < 800;
  };

  if (typeof window !== 'undefined' && getQuerystringValue('redirectTo')) {
    redirectTo = getQuerystringValue('redirectTo');
    setStorage('forcedPage', `${window.location.origin}${redirectTo}`, true);
  }

  useEffect(() => {
    if (!pageLoaded && isLoggedIn && typeof window !== 'undefined') {
      window.location = '/account/';
    }
    if (userChecked) {
      setLoaded(true);
    }
  }, [isLoggedIn, pageLoaded, userChecked]);

  if (!isLoggedIn && userChecked) {
    return (
      <Layout className={styles.root}>
        <Seo title="Pet Chemist Online - Sign in" showSiteName={false} />
        <Container size={'large'}>
          <div className={`grid grid-50 ${styles.loginContainer}`}>
            <LoginForm isLoggedIn={isLoggedIn && userChecked} handleLogin={handleLogin} />
            <div className={styles.createAccount}>
              <h1 className="h6-login new-customer-text">New customer?</h1>
              <p className="subtitle">Create an account with us and you'll be able to:</p>
              <ul className="bullets">
                <li>Check out quickly every time</li>
                <li>Track your orders</li>
                <li>Manage any subscriptions</li>
                <li>Receive weekly discounts and coupons</li>
              </ul>
              <Button onClick={() => navigate('/signup/')} level="tertiary" type="button">
                Create account
              </Button>
            </div>
          </div>
          <Center>
            <Image
              m={0}
              style={{
                width: isMadPawsStore() ? (isMobile() ? '60%' : '30%') : 'initial',
                height: isMadPawsStore() ? (isMobile() ? '60%' : '30%') : 'initial'
              }}
              mt={4}
              src={isMadPawsStore() ? '/login_mps.png' : '/login.png'}
              alt="login"
            />
          </Center>
        </Container>
      </Layout>
    );
  } else {
    return null;
  }
};

export default LoginPage;
