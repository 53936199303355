import { Box, Center, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { useForm } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import ChevronLeftIcon from 'components/atoms/Icon/chevron-left';
import React, { useContext, useMemo, useEffect, useState } from 'react';
import ReviewContext from '../../../context/ReviewProvider';
import Button from '../../atoms/Button/Button';
import Container from '../../atoms/Container/Container';
import ReviewCard from '../../atoms/ReviewCard/ReviewCard';
import ReviewForm from '../Reviews/ReviewForm';
import * as pcStyles from './ReviewSection.module.scss';
import * as mpsStyles from './ReviewSection.mps.module.scss';
import { storeStyle } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const ReviewSection = ({ product, hash }) => {
  const ctxReview = useContext(ReviewContext);
  const showReviewForm = ctxReview && ctxReview.showReviewForm;
  const form = useForm({
    initialValues: {
      page: undefined
    }
  });

  const { data: reviews, isLoading } = useQuery(
    ['product-review', product.entityId, form.values.page],
    async () => {
      let reviewId = null;
      if (!!hash && hash.includes('review')) {
        const id = hash.split('-').pop();
        if (id) reviewId = id;
      }

      const response = await ctxReview.getReviews(product.entityId, form.values.page, reviewId);
      if (form.values.page === undefined) {
        form.setFieldValue('page', response?.meta?.pagination?.total_pages);
      }
      if (response?.data?.length === 0) {
        response.data.reverse();
      }
      return response;
    }
  );

  const getPages = useMemo(() => {
    const pages = [];

    const startPage = Math.min(
      reviews?.meta?.pagination?.total_pages,
      reviews?.meta?.pagination?.current_page + 1
    );

    const endPage = Math.max(1, startPage - 2);

    for (let i = startPage; i >= endPage; i--) {
      pages.push(i);
    }

    return pages;
  }, [reviews?.meta?.pagination?.total_pages, reviews?.meta?.pagination?.current_page]);

  const scrollToReviews = pageNumb => {
    if (pageNumb === reviews?.meta?.pagination.total_pages) {
      setIsFirstPage(true);
    } else {
      setIsFirstPage(false);
    }
    form.setFieldValue('page', pageNumb);
    document.getElementById('reviews-section').scrollIntoView();
  };

  return (
    <section className={styles.Reviews} id="reviews-section">
      <Container size="large">
        <div className="flex-between">
          <h4>{reviews?.meta?.pagination?.total ?? 0} Reviews</h4>
          <Button type={'span'} onClick={showReviewForm} level={'ghost'} className={styles.write}>
            LEAVE REVIEW
          </Button>
        </div>
        <div className={styles.ReviewsContainer}>
          {isLoading ? (
            <>
              <Box padding="6" bg="white">
                <SkeletonCircle size="10" />
                <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
              </Box>
              <Box padding="6" bg="white">
                <SkeletonCircle size="10" />
                <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
              </Box>
            </>
          ) : (
            <>
              {reviews?.data?.length > 0 ? (
                reviews?.data?.map(review => (
                  <ReviewCard
                    reviewId={review.id}
                    name={review.name}
                    date={review.date_reviewed}
                    rating={review.rating}
                    content={review.text}
                    title={review.title}
                  />
                ))
              ) : (
                <Center h={300}>
                  <h3>No reviews yet</h3>
                </Center>
              )}
            </>
          )}
        </div>
        <div>
          {reviews && reviews?.meta?.pagination.total_pages > 1 && (
            <div className={styles.Pagination}>
              <>
                <button
                  className="first"
                  disabled={
                    reviews?.meta?.pagination.current_page === reviews?.meta?.pagination.total_pages
                  }
                  onClick={() => {
                    scrollToReviews(reviews?.meta?.pagination.current_page + 1);
                  }}
                >
                  <ChevronLeftIcon />
                </button>

                {reviews?.meta?.pagination.total_pages - 1 > form.values.page && (
                  <button
                    className="last"
                    disabled={form.values.page === reviews?.meta?.pagination.total_pages}
                    onClick={() => {
                      scrollToReviews(reviews?.meta?.pagination.total_pages);
                    }}
                  >
                    1
                  </button>
                )}

                {reviews?.meta?.pagination.total_pages - 2 > form.values.page && <>...</>}

                {getPages.map(pageNum => (
                  <button
                    key={pageNum}
                    onClick={() => {
                      scrollToReviews(pageNum);
                    }}
                    disabled={pageNum === form.values.page ? 'active' : ''}
                    className={pageNum === form.values.page ? 'active' : ''}
                  >
                    {pageNum === reviews?.meta?.pagination.total_pages
                      ? 1
                      : reviews?.meta?.pagination.total_pages - pageNum + 1}
                  </button>
                ))}

                {form.values.page > 3 && <>...</>}

                {form.values.page > 3 && (
                  <>
                    <button
                      className="last"
                      disabled={form.values.page === 1}
                      onClick={() => {
                        scrollToReviews(reviews?.meta?.pagination.current_page - 1);
                      }}
                    >
                      {reviews?.meta?.pagination.total_pages}
                    </button>
                  </>
                )}
                {/* {form.values.page !== 1 && ( */}
                <button
                  className="last"
                  disabled={form.values.page === 1}
                  onClick={() => {
                    scrollToReviews(reviews?.meta?.pagination.current_page - 1);
                  }}
                >
                  <ChevronLeftIcon />
                </button>
                {/* )} */}
              </>
            </div>
          )}
        </div>
      </Container>
      <ReviewForm product={product} />
    </section>
  );
};

export default ReviewSection;
